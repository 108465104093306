import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1648145321/maternidad2_elspp5.jpg",
        "alt": "Bethzait Becerra"
      }}></img></p>
    <p>{`Comunicar, expresarse, entenderse con los otros es de por sí un desafío. Aunque hay a quienes esto no parece causarles ningún conflicto y dan la impresión de pasar toda una vida sin planteárselo siquiera. Para mí, estos cuestionamientos se me han vuelto padecimientos crónicos potenciados por mi situación de migrante. `}<strong parentName="p">{`Esta entrada surge de una experiencia vivida hace pocos días, frente a una madre en duelo y la incapacidad de poner en palabras mi empatía ¿De no haber tantos puentes culturales habría logrado algo, aunque fuese ínfimo? ¿recurrir a mis hábitos culturales habría sido prudente? ¿cómo abrazarla?`}</strong></p>
    <p>{`La verdad es que el tema de la lengua no sólo me obsesiona al grado de haberlo convertido en mi trabajo, sino que en torno a ello gira cada uno de mis pasatiempos. La ambigüedad de la palabra me ha comido la cabeza desde siempre, recuerdo muy bien que mis arranques adolescentes venían de la sensación de sentirme incomprendida y no por ninguna otra razón más que por la incapacidad de no saber expresar lo que sentía. Con el paso de los años mejoré, aprendí a desarrollar un discurso más o menos articulado. Aprendí a escuchar, sobre todo. Aprendí también que en la vida muchas veces nos encontraremos animando diálogos de besugos y que eso tampoco es tan grave. Estas conversaciones inconexas son más fáciles de identificar, por ejemplo, cuando una no está enfrascada. Inundan el paisaje sonoro de los recorridos largos en el transporte público, los asilos para ancianos, o las conversaciones de las parejas que llevan demasiado tiempo juntas. Todas están dotadas de una belleza torpe, egocentrista y tremendamente solitaria. Porque esa es otra, la soledad humana. Vivir en otro idioma reúne ambas, la soledad y la frustración de la lengua. Afortunadamente con el tiempo se hace más llevadero. Hasta que algo te lo recuerda de golpe y pierdes pie.`}</p>
    <p>{`El año pasado descubrí que estaba embarazada y al poco tiempo una de mis colegas, madre de un niño, me confesó que ella también. El embarazo pareció acercarnos, y comenzamos a desarrollar algo con tintes de amistad. Compartíamos experiencias y nos dábamos ánimos, yo la escuchaba atentamente porque ella tenía experiencia en el asunto. Además de eso hablábamos de nuestros países de origen, ella de un país de Europa del Este y yo de uno de América Latina, entre tanta diferencia había vasos comunicantes que estrechaban nuestra relación, pero esto duró poco porque a causa de los riesgos del COVID nos mandaron a casa. Continuamos enviándonos mensajes esporádicamente. Nunca me llamó, nunca la llamé. A mí sigue sin gustarme mantener conversaciones telefónicas en francés, no sé si a ella le pasé lo mismo. Llegó el verano y el momento del parto. Di a luz una niña sana.`}</p>
    <p>{`Pasaron los días y decidí escribirle, sabía que ella daría a luz por las mismas fechas.`}</p>
    <p><strong parentName="p">{`Me respondió que su bebé nació muerto.`}</strong></p>
    <p><strong parentName="p">{`Leí y releí el mensaje muchas veces, no podía ser cierto.`}</strong></p>
    <p><strong parentName="p">{`Me descompuse. Me derramé en un llanto incontrolable. Me invadió una tristeza desesperada, un dolor que venía desde lo más profundo. Un dolor de madre.`}</strong></p>
    <p>{`Intenté aplacarme el llanto para escribirle. No me atreví a llamarle. No habría contestado de cualquier forma. Reescribí varias veces, me frustraba tener que hacerlo en francés, quería estar segura de las palabras, aunque en esos momentos no hay palabra que valga. Quería salir corriendo a abrazarla, pero tampoco podía hacerlo. Acababa de tener una hija.`}</p>
    <p>{`No me respondió y no volví a escribirle. No dejaba de pensar en ella. Pasados unos meses me dijo que tenía intenciones de retomar el trabajo, eso le ayudaría. Dejé de tener noticias suyas. `}<strong parentName="p">{`Me concentré en mi bebita y en sanar mi cuerpo, las complicaciones de mi parto hicieron que pasara mucho tiempo en consultorios médicos, en laboratorios y rehabilitaciones. Los pospartos son otra zona gris en el imaginario colectivo, poco se habla de ellos.`}</strong></p>
    <p>{`Pasaron otros meses hasta que pude volver a trabajar. La coincidencia hizo que ambas retomáramos la chamba la misma semana. Nos saludamos con una sonrisa, sin más, sin intenciones de detenernos a preguntarnos nada y con la excusa de andar a las carreras. Los colegas nos miraban con escrutinio, con incomodidad. No era hasta que me agarraban sola por los pasillos que me felicitaban por el `}<em parentName="p">{`heureux événement`}</em>{` y para pedirme que les mostrara fotos de mi cría. Sino, todos pretendían mirar hacia otro lado y evitar que saliera a relucir algo que recordase nuestras maternidades contrastantes.`}</p>
    <p>{`Ella y yo nos vimos y hablamos poco por esos días. Yo andaba de arriba para abajo aprovechando cada descanso para tirarme la leche y prolongar la lactancia materna. Ella desapareció unos días porque la guardería de su hijo cerró temporalmente debido a una `}<em parentName="p">{`contagiadera`}</em>{` de COVID.`}</p>
    <p>{`Esta semana coincidimos en el comedor, debido a las normas de distanciamiento social no pudimos sentarnos en la misma mesa, me senté en una mesa contigua. Sentimos la mirada de los demás. Se hablaba del contexto laboral y eso nos dio oportunidad de permanecer en silencio.`}</p>
    <p>{`Horas después nos encontramos solas en la sala de maestros. Frente a frente cada una corrigiendo copias. Intenté comenzar una conversación irrelevante. Ella fue al grano preguntándome por mi bebé, la lactancia, la organización en casa. Respondía intentando alejar el tema hacia otras direcciones, pero ella volvía. Se acercaba a grandes pasos lo que después de tanto tiempo, de tanto evadirlo, ya no sabía ni cómo abordar. Era lo justo, era mi turno de preguntarle cómo se encontraba ella, aprovechar que por primera vez estábamos solas y tocar el tema. Y entramos en un terreno desolador.`}</p>
    <p>{`Al poco rato, afortunada o desafortunadamente entró un colega y guardamos silencio. Ella salió de la sala y yo a mi vez aproveché también para salir, tenía que sacarme la leche, pero apenas cerré la puerta me puse a llorar de impotencia, de coraje contra mí. Debí haberla abrazado, pero la mesa entre ambas parecía infranqueable. Lo cierto es que aquí hay tantos puentes culturales que uno se extravía. Y me perdí sin saber qué hacer ni cómo hacerlo. Sus palabras me atraviesan todavía el corazón. `}<strong parentName="p">{`La escuché e intenté reconfortarla (la intención ya es de por sí muy estúpida de mi parte) mis palabras se estrellaban unas contra otras, toscas, vacías. Sin peso. Estoy segura que ella tampoco logró decirme precisamente lo que quería.`}</strong>{` Ambas patinamos en una lengua que no es la nuestra. Todo lo que decimos es aún más aproximativo. Hay una dimensión de la palabra que no alcanza nunca a decirse. En este caso sólo hay silencio. Un silencio triste e infinito. Esta semana volveré a verla y espero que me permita abrazarla.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      